import gql from "graphql-tag";

export const GET_CITY_DATA = gql`
  subscription GET_CITY_DATA {
    covid_summary_by_city {
      ill
      city
      lat
      lng
    }
  }
`;
