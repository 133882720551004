import * as React from "react";
import { IconProps } from "../icon.props";

const Close: React.FC<IconProps> = ({
  width = 12,
  height = 12,
  viewBox = "0 0 12 12",
  fill = "white",
  fillOpacity = "1",
  className = "cursor-pointer",
}) => {
  return (
    <svg width={width} height={height} className={className} viewBox={viewBox}>
      <path
        d="M7.32217 6.00003L11.8091 1.51289C11.9325 1.38937 12.0006 1.22457 12.0008 1.04885C12.0008 0.873037 11.9327 0.70805 11.8091 0.584724L11.4159 0.191623C11.2923 0.0678097 11.1275 0 10.9516 0C10.776 0 10.6112 0.0678097 10.4876 0.191623L6.00062 4.67848L1.51347 0.191623C1.39005 0.0678097 1.22516 0 1.04934 0C0.87372 0 0.70883 0.0678097 0.585407 0.191623L0.192014 0.584724C-0.0640045 0.840742 -0.0640045 1.25716 0.192014 1.51289L4.67906 6.00003L0.192014 10.487C0.0684926 10.6107 0.000487839 10.7755 0.000487839 10.9512C0.000487839 11.1269 0.0684926 11.2917 0.192014 11.4153L0.585309 11.8084C0.708733 11.9322 0.87372 12.0001 1.04924 12.0001C1.22506 12.0001 1.38995 11.9322 1.51337 11.8084L6.00052 7.32149L10.4875 11.8084C10.6111 11.9322 10.7759 12.0001 10.9515 12.0001H10.9517C11.1274 12.0001 11.2922 11.9322 11.4158 11.8084L11.809 11.4153C11.9324 11.2918 12.0006 11.1269 12.0006 10.9512C12.0006 10.7755 11.9324 10.6107 11.809 10.4871L7.32217 6.00003Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default Close;
