import { format } from "date-fns";
import { TimeRange } from "src/enums/selectedTimeType";
import {
  selectCurrentWeekDates,
  selectCurrentMonthDates,
} from "../RightSidebar/utils";

export const parseChartData = (data: any[]) => {
  let activeDate = "";
  return data.reduce((acc: any, curr: any) => {
    const currDateFormat = curr.date
      ? format(new Date(curr.date), "MMM dd")
      : curr.time;
    const initialObj = {
      [curr.region]: curr.ill,
      date: currDateFormat,
    };
    const lastIndex = acc.length - 1;
    if (activeDate === currDateFormat) {
      const temp = [...acc];
      temp[lastIndex] = Object.assign(temp[lastIndex], initialObj);
      return temp;
    }
    activeDate = currDateFormat;
    return [...acc, initialObj];
  }, []);
};

export const timeRanges = [
  TimeRange.month,
  TimeRange.week,
  TimeRange.day,
  TimeRange.hour,
];

export const getDates = (range: TimeRange) => {
  switch (range) {
    case TimeRange.week: {
      return selectCurrentWeekDates();
    }
    case TimeRange.month: {
      return selectCurrentMonthDates();
    }
  }
};

export const getfromYesterdayData = () => {
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const currentHour = new Date().toISOString().slice(11, 19);
  return {
    date: yesterday,
    time: currentHour,
  };
};

export const getByHourData = () => {
  const currentHour = new Date().toISOString().slice(11, 19);
  const today = new Date().toISOString().slice(0, 10);
  const hourAgo = new Date(new Date().getTime() - 1000 * 60 * 60)
    .toISOString()
    .slice(11, 19);

  return {
    day: today,
    start: hourAgo,
    end: currentHour,
  };
};

export const selectActiveRangeData = (
  active: TimeRange,
  daysData: any,
  hoursData: any
) => {
  const isDaysData = [TimeRange.month, TimeRange.week].includes(active);
  if (isDaysData) {
    return daysData;
  }
  return hoursData;
};
