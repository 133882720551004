import * as React from "react";
import { TimeRange } from "src/enums/selectedTimeType";
import RangeBarItem from "./RangeBarItem.component";
import { useSubscription } from "@apollo/react-hooks";
import { GET_DATA_BY_DAYS } from "src/queries/getDataByDays";
import {
  parseChartData,
  getDates,
  getByHourData,
  selectActiveRangeData,
  timeRanges,
  getfromYesterdayData,
} from "./utils";
import { GET_DATA_BY_HOURS } from "src/queries/getDataByHours";
import { GET_ARABIC_REGIONS_NAMES } from "src/queries/getArabicRegions";
import { GET_COUNTRY_COLORS } from "src/queries/getCountryColors";
import ConfirmedChart from "./ConfirmedChart.compoent";
import NoChartData from "./NoChartData.component";
import Legend from "./Legend.component";
import { GET_DATA_FROM_YESTERDAY } from "src/queries/getDataFromYesterday";

const ConfirmedCasesChart: React.FC = () => {
  const [activeRange, setActiveRange] = React.useState(TimeRange.week);
  const { data: daysData, loading: daysLoading } = useSubscription(
    GET_DATA_BY_DAYS,
    {
      variables: { dates: getDates(activeRange) },
    }
  );
  const {
    data: fromYesterdayData,
    loading: fromYesterdayLoading,
  } = useSubscription(GET_DATA_FROM_YESTERDAY, {
    variables: getfromYesterdayData(),
  });
  const { data: hoursData, loading: hoursLoading } = useSubscription(
    GET_DATA_BY_HOURS,
    {
      variables: getByHourData(),
    }
  );
  const { data: regionsName, loading: regionsNameLoading } = useSubscription(
    GET_ARABIC_REGIONS_NAMES
  );
  const { data: colors, loading: colorsLoading } = useSubscription(
    GET_COUNTRY_COLORS
  );

  const isDataLoading =
    daysLoading ||
    hoursLoading ||
    regionsNameLoading ||
    colorsLoading ||
    fromYesterdayLoading;
  if (isDataLoading) {
    return null;
  }

  const selectDailyData =
    activeRange === TimeRange.day ? fromYesterdayData : hoursData;
  const daysChartData = daysData ? daysData.covid_summary_by_date_region : [];
  const hoursChartData = hoursData
    ? selectDailyData.covid_summary_by_hours
    : [];
  const finalChartData = parseChartData(
    selectActiveRangeData(activeRange, daysChartData, hoursChartData)
  );
  return (
    <div className="border border-gray-700 rounded mt-24 pt-4 pc:w-2/4 laptop:w-11/12 tablet:w-11/12 phone:w-11/12">
      <div className="flex flex-row w-full">
        <div className="w-full">
          <h3 className="text-white text-lg mt-4 ml-16">
            Total Confirmed Cases by Day
          </h3>
          <div className="flex flex-row items-center justify-start ml-16 mt-4 mb-4">
            {timeRanges.map((range, index) => (
              <RangeBarItem
                selected={activeRange}
                key={index}
                range={range}
                setRange={setActiveRange}
              />
            ))}
          </div>
          {finalChartData.length ? (
            <ConfirmedChart
              chartData={finalChartData}
              colors={colors}
              regions={regionsName}
            />
          ) : (
            <NoChartData />
          )}
        </div>
        {regionsName.covid_arabic_regions.length ? (
          <div className="mt-12 mr-4">
            <Legend
              colors={colors}
              regions={regionsName.covid_arabic_regions}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ConfirmedCasesChart;
