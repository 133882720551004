import * as React from "react";
import { IconProps } from "../icon.props";

const SortTop: React.FC<IconProps> = ({
  width = 10,
  height = 8,
  viewBox = "0 0 7 3",
  fill = "white",
  fillOpacity = "0.2",
  className = "cursor-pointer",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={viewBox}
      {...props}
    >
      <path
        d="M3.195 0.234787C3.37478 0.0963909 3.62522 0.0963909 3.805 0.234787L6.23291 2.1038C6.61115 2.39497 6.40525 3 5.92792 3L1.07208 3C0.594749 3 0.388845 2.39497 0.767087 2.1038L3.195 0.234787Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default SortTop;
