import * as React from "react";
import { IconProps } from "../icon.props";

const SortBottom: React.FC<IconProps> = ({
  width = 10,
  height = 8,
  viewBox = "0 0 7 3",
  fill = "white",
  fillOpacity = "0.2",
  className = "cursor-pointer",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={viewBox}
      {...props}
    >
      <path
        d="M3.805 2.76521C3.62522 2.90361 3.37478 2.90361 3.195 2.76521L0.767088 0.896203C0.388846 0.605032 0.59475 -6.78683e-08 1.07208 -1.09598e-07L5.92792 -5.34108e-07C6.40525 -5.75838e-07 6.61116 0.605031 6.23291 0.896202L3.805 2.76521Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default SortBottom;
