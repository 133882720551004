export const headers = [
  { label: "Cases", sort: "ill" },
  { label: "Death", sort: "dead" },
  { label: "Mortality(%)", sort: "mortality" },
];

export const body = [
  { region: "Adir", cases: "56", death: "1", mortality: "2.6" },
  { region: "Bahah", cases: "65", death: "-", mortality: "4.7" },
  { region: "Eastern Province", cases: "67", death: "-", mortality: "5.0" },
  { region: "Ha'il", cases: "5", death: "-", mortality: "7.9" },
  { region: "Jawf", cases: "3", death: "-", mortality: "2.6" },
  { region: "Jizan", cases: "78", death: "4", mortality: "4.7" },
  { region: "Madinah", cases: "56", death: "-", mortality: "5.0" },
  { region: "Makkah", cases: "78", death: "3", mortality: "7.9" },
  { region: "Najran", cases: "95", death: "-", mortality: "2.6" },
  { region: "Northern Borders", cases: "135", death: "1", mortality: "4.7" },
  { region: "Qassim", cases: "7", death: "-", mortality: "5.0" },
  { region: "Riyadh", cases: "564", death: "7", mortality: "7.9" },
  { region: "Tabuk", cases: "7", death: "-", mortality: "2.6" },
];
