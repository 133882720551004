import * as React from "react";
import { format } from "date-fns";

const Header: React.FC = () => {
  return (
    <div className="bg-black-300 w-full ">
      <div className="h-16 flex flex-row justify-between items-center ml-16">
        <h1 className="text-white pc:text-3xl laptop:text-2xl tablet:text-xl phone:text-lg">
          Confirmed COVID-19 Cases and Spread in the Kindom of Saudi Arabia
        </h1>
        <span className="text-white text-base ml-12 mt-3 opacity-50 mr-24">
          Last updated: {format(new Date(), "MMMM, dd, yyyy, HH:mm")}
        </span>
      </div>
    </div>
  );
};

export default Header;
