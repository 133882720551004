import * as React from "react";
import LegendIcon from "src/styles/svg/LegendIcon/LegendIcon.component";
import ClickOutside from "../DatasetModal/ClickOutside.component";

const Legend: React.FC<{
  colors: { colors: { color: string }[] };
  regions: { region: string }[];
}> = ({ colors, regions }) => {
  const [isVisisbleList, setListVisible] = React.useState(false);

  const changeListStatus = React.useCallback(
    (status: boolean) => () => {
      setListVisible(status);
    },
    [setListVisible]
  );

  return (
    <ClickOutside handleClick={() => setListVisible(false)}>
      <div className="flex flex-col justify-start items-center border rounded border-gray-800 -ml-32 cursor-pointer mr-4">
        <div
          className="flex flex-row items-center justify-between w-full bg-gray-800"
          onClick={changeListStatus(!isVisisbleList)}
        >
          <span className="text-white opacity-50 text-base ml-2">Legend</span>
          <div className={`ml-2 mr-2 ${isVisisbleList ? "rotate-180" : ""}`}>
            <LegendIcon fillOpacity="0.5" />
          </div>
        </div>
        {isVisisbleList && (
          <div className="flex flex-col justify-start items-start bg-black-300 pr-4 pt-2 z-50 w-56">
            {regions.map(({ region }, index: number) => (
              <div
                className="flex flex-row justify-start items-center"
                key={index}
              >
                <span
                  className="w-8 h-1 ml-4"
                  style={{ backgroundColor: colors.colors[index].color }}
                />
                <span className="text-white text-base ml-4">{region}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default Legend;
