import * as React from "react";
import { IconProps } from "../icon.props";

const LegendIcon: React.FC<IconProps> = ({
  width = 5,
  height = 8,
  viewBox = "0 0 5 8",
  fill = "white",
  fillOpacity = "1",
  className = "cursor-pointer",
}) => {
  return (
    <svg width={width} height={height} className={className} viewBox={viewBox}>
      <path
        d="M0.364628 7.26813L0.121002 7.02619C0.0449178 6.95004 0.00300231 6.84874 0.00300232 6.74047C0.00300232 6.63225 0.0449178 6.53083 0.121002 6.45468L2.88106 3.69475L0.11794 0.931629C0.0418554 0.855604 -3.29662e-08 0.754178 -2.82361e-08 0.645967C-2.3506e-08 0.537755 0.0418555 0.436269 0.11794 0.360185L0.360065 0.11818C0.517518 -0.0393933 0.773995 -0.0393933 0.931449 0.11818L4.2331 3.408C4.30913 3.48403 4.36269 3.58533 4.36269 3.69451L4.36269 3.69577C4.36269 3.80404 4.30907 3.90535 4.2331 3.98137L0.940396 7.26813C0.864371 7.34428 0.760003 7.38607 0.651791 7.38619C0.54352 7.38619 0.440593 7.34428 0.364628 7.26813Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default LegendIcon;
