import * as React from "react";

const NoChartData: React.FC = () => {
  return (
    <div className="flex items-center justify-center w-full h-24">
      <span className="text-2xl text-white">No Data</span>
    </div>
  );
};

export default NoChartData;
