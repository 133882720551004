import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ConfirmedChart: React.FC<any> = ({ chartData, regions, colors }) => {
  return (
    <ResponsiveContainer width="100%" height={380}>
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip
          contentStyle={{ backgroundColor: "#36363E" }}
          labelStyle={{ color: "white" }}
        />
        {regions.covid_arabic_regions.map(
          ({ region }: { region: string }, index: number) => (
            <Line
              type="monotone"
              dataKey={region}
              stroke={
                colors.colors[index] ? colors.colors[index].color : "#ffffff"
              }
              key={index}
            />
          )
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ConfirmedChart;
