import * as React from "react";
import PercentCard from "./PercentCard.component";
import InfectedCard from "./InfectedCard.component";
import { StatTypes } from "src/enums/statTypes";

const LeftSidebar: React.FC<{
  cards: { type: StatTypes; percent: number }[];
}> = ({ cards }) => {
  return (
    <div className="flex flex-col ml-8">
      <InfectedCard />
      {cards.map((card, index) => (
        <PercentCard type={card.type} percent={card.percent} key={index} />
      ))}
    </div>
  );
};

export default LeftSidebar;
