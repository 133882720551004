import * as React from "react";
import SortTop from "src/styles/svg/SortTop/SortTop.component";
import SortBottom from "src/styles/svg/SortBottom/SortBottom.component";
import { headers } from "./gridData";

const setMarkerStatus = (sort: any, field: any, data: any) => {
  if (data[field] === sort) {
    return "1";
  }
  return "0.2";
};

const RegionHeaders: React.FC<any> = ({ setOrderBy, orderBy }) => {
  const setSort = React.useCallback(
    (field: string, direction: string) => {
      setOrderBy({ [field]: direction });
    },
    [setOrderBy]
  );

  return (
    <div className="flex flex-row justify-between items-center w-full border-b border-gray-300  pb-2 mb-2">
      <div className="flex flex-row w-20">
        <span className="text-white text-base opacity-50">Regions</span>
        <div className="flex flex-col mt-1 ml-1">
          <SortTop
            className="cursor-pointer"
            onClick={() => setSort("region", "asc")}
            fillOpacity={setMarkerStatus("asc", "region", orderBy)}
          />
          <SortBottom
            className="cursor-pointer"
            onClick={() => setSort("region", "desc")}
            fillOpacity={setMarkerStatus("desc", "region", orderBy)}
          />
        </div>
      </div>
      <div className="flex flex-row">
        {headers.map((header, index) => (
          <div
            className="flex flex-row mr-4 w-20 justify-center items-center"
            key={index}
          >
            <span className="text-white text-base opacity-50">
              {header.label}
            </span>
            <div className="flex flex-col mt-1 ml-1">
              <SortTop
                className="cursor-pointer"
                onClick={() => setSort(header.sort, "asc")}
                fillOpacity={setMarkerStatus("asc", header.sort, orderBy)}
              />
              <SortBottom
                className="cursor-pointer"
                onClick={() => setSort(header.sort, "desc")}
                fillOpacity={setMarkerStatus("desc", header.sort, orderBy)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegionHeaders;
