import * as React from "react";
import Case from "../Case/Case.component";
import { Cases, CasesFieldRef } from "src/enums/cases";
import LeftSidebar from "../RightSidebar/LeftSidebar.component";
import { useSubscription } from "@apollo/react-hooks";
import { GET_GENERAL_DATA } from "src/queries/getGeneralData";
import { StatTypes } from "src/enums/statTypes";
import { DAILY_ILL } from "src/queries/dailyDiff";
import DatasetModal from "../DatasetModal/DatasetModal.component";
import { SELECT_STAT_DATA_BY_DAY } from "src/queries/selectGeneralDataByDay";
import { GET_DAILY_CHART } from "src/queries/getDailyChart";
import { GET_INFECTED_REGIONS_DATA } from "src/queries/getInfectedRegionsData";
import { GET_CITY_DATA } from "src/queries/getCityData";
import Stat from "../Stat/Stat.component";

const makePercent = (from: number, to: number) => {
  return parseInt(((from / to) * 100).toFixed(1));
};

const PageLayout: React.FC = () => {
  const [isOpenModal, changeModalStatus] = React.useState(false);

  const [orderBy, setOrderBy] = React.useState({ ill: "desc" });
  const {
    data: dailyChartData,
    loading: dailyChartDataLoading,
  } = useSubscription(GET_DAILY_CHART);

  const {
    data: regionsData,
    loading: regionsDataLoading,
  } = useSubscription(GET_INFECTED_REGIONS_DATA, { variables: { orderBy } });

  const {
    data: mapRegionsData,
    loading: mapRegionsDataLoading,
  } = useSubscription(GET_INFECTED_REGIONS_DATA, {
    variables: { orderBy: { ill: "asc" } },
  });

  const { data: cityData, loading: cityDataLoading } = useSubscription(
    GET_CITY_DATA
  );

  const { data: dailyData, loading: illLoading } = useSubscription(DAILY_ILL, {
    variables: {
      today: new Date().toISOString().slice(0, 10),
    },
  });

  const {
    data: statGeneralData,
    loading: statGeneralLoading,
  } = useSubscription(SELECT_STAT_DATA_BY_DAY);

  const { data, loading } = useSubscription(GET_GENERAL_DATA);
  const isDataLoading =
    loading ||
    illLoading ||
    statGeneralLoading ||
    dailyChartDataLoading ||
    regionsDataLoading ||
    cityDataLoading ||
    mapRegionsDataLoading;

  const openDatasetModal = React.useCallback(() => {
    changeModalStatus(!isOpenModal);
    document.body.style.overflow = "hidden";
  }, [isOpenModal, changeModalStatus]);

  if (isDataLoading) {
    return null;
  }
  const {
    covid_data_aggregate: {
      aggregate: {
        sum: { currently_ill, dead, recovered },
      },
    },
  } = data;
  const {
    covid_data_aggregate: {
      aggregate: {
        sum: { currently_ill: dailyIll },
      },
    },
  } = dailyData;
  const { covid_stat_general } = statGeneralData;
  const confirmedCases = currently_ill + recovered;
  const cards = [
    {
      type: StatTypes.daily,
      percent: makePercent(dailyIll, confirmedCases),
    },
    {
      type: StatTypes.recovery,
      percent: makePercent(recovered, confirmedCases),
    },
    {
      type: StatTypes.mortality,
      percent: makePercent(dead, confirmedCases),
    },
  ];

  return (
    <div>
      <div className="flex flex-row justify-between items-center pc:mr-16 laptop:mr-8 tablet:mr-0 phone:mr-0">
        <span className="text-white  mb-8">
          Overview of the Kindom of Saudi Arabia
        </span>
        <div
          className="border border-green-100 rounded pt-2 pb-2 pr-8 pl-8  mb-6 cursor-pointer"
          onClick={openDatasetModal}
        >
          <span className="uppercase text-green-100 text-base opacity-50">
            Update dataset
          </span>
        </div>
      </div>
      <div>
        <div className="flex flex-row -4">
          <div className="flex flex-col">
            <Case
              type={Cases.confirmed}
              casesAmount={confirmedCases}
              data={covid_stat_general}
              refField={CasesFieldRef.confirmed}
            />
            <div className="mt-8">
              <Case
                type={Cases.recovered}
                casesAmount={recovered}
                data={covid_stat_general}
                refField={CasesFieldRef.recovered}
              />
            </div>
            <div className="mt-8">
              <Case
                type={Cases.active}
                casesAmount={currently_ill}
                data={covid_stat_general}
                refField={CasesFieldRef.ill}
              />
            </div>
            <div className="mt-8">
              <Case
                type={Cases.deaths}
                casesAmount={dead}
                data={covid_stat_general}
                refField={CasesFieldRef.dead}
              />
            </div>
          </div>
          <Stat
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            dailyChartData={dailyChartData}
            regionsData={regionsData}
            mapRegionsData={mapRegionsData}
            cityData={cityData}
          />
          <div className="w-1/5">
            <LeftSidebar cards={cards} />
          </div>
        </div>
      </div>
      {isOpenModal && <DatasetModal onClose={changeModalStatus} />}
    </div>
  );
};

export default PageLayout;
