import gql from "graphql-tag";

export const INSERT_USER = gql`
  mutation INSERT_USER($id: uuid!) {
    insert_users(objects: { id: $id }) {
      returning {
        id
      }
    }
  }
`;
