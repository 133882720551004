import React from "react";
import "./styles/app.css";
import "./App.css";
import { createApolloClient } from "./client";
import { ApolloProvider } from "@apollo/react-hooks";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";
import Dashboard from "./modules/Dashboard/Dashboard.component";
import { Routes } from "./enums/routes";

const history = createBrowserHistory();

function App() {
  const client = createApolloClient() as any;
  return (
    <div className="w-full h-screen">
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <Route exact path={Routes.publicDashboard} component={Dashboard} />
            <Route
              path={`${Routes.privateDashboard}/:id`}
              component={Dashboard}
            />
          </Switch>
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
