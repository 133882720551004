import * as React from "react";
import RegionHeaders from "./RegionHeaders.component";
import RegionsRow from "./RegionsRow.component";
import { RegionsTableProps } from "./types";

const RegionsTable: React.FC<RegionsTableProps> = ({
  regionsData,
  setOrderBy,
  orderBy,
}) => {
  return (
    <div className="flex flex-col justify-start items-start pc:ml-8 laptop:ml-0 tablet:ml-0 phone:ml-0 pc:mt-0 laptop:mt-4 tablet:mt-4 phone:mt-4 mr-4">
      <h3 className="text-white text-xl">
        Data by Regions of the Kindom of Saudi Arabia
      </h3>
      <div className="flex flex-col w-full">
        <RegionHeaders setOrderBy={setOrderBy} orderBy={orderBy} />
        {regionsData.map(({ region, ill, dead }: any, index: any) => (
          <RegionsRow
            region={region}
            cases={ill}
            key={index}
            mortality={
              !isNaN((dead / ill) * 100) ? ((dead / ill) * 100).toFixed(1) : 0
            }
            death={dead}
          />
        ))}
      </div>
    </div>
  );
};

export default RegionsTable;
