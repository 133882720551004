import * as React from "react";
import mapboxgl from "mapbox-gl";
import { generateCityLayout, generateRegionsLayout } from "./utils";
import legend from "../../styles/legend.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoidG9tb3lheDEiLCJhIjoiY2s4a2Ntb3BwMDBkeDNsbzVyYzBzZXk2MSJ9.wZBeZ1LijGwIu8BXHxMegQ";

const mapClassName =
  "mapboxgl-canvas-container mapboxgl-interactive mapboxgl-touch-drag-pan mapboxgl-touch-zoom-rotate";

const AffectedMap: React.FC<any> = ({ regionsData, cityData }) => {
  let mapboxElRef = React.useRef() as any;
  let addZoomRef = React.useRef() as any;
  let substractZoomRef = React.useRef() as any;

  React.useEffect(() => {
    const map: any = new mapboxgl.Map({
      container: mapboxElRef.current,
      style: "mapbox://styles/tomoyax1/ck8u77gsv139e1in0pgxvuq30",
      center: [45, 25],
    });
    const maps = document.getElementsByClassName(mapClassName);
    if (maps.length > 1) {
      maps[0].remove();
    }
    map.on("load", () => {
      map.zoomTo(4.2);
      map.setMinZoom(4.2);
      map.resize();
      generateRegionsLayout(map, regionsData);
      generateCityLayout(map, cityData);
    });
    addZoomRef.current.addEventListener("click", (e: any) => {
      map.zoomTo(map.getZoom() + 1);
    });
    substractZoomRef.current.addEventListener("click", (e: any) => {
      map.zoomTo(map.getZoom() - 1);
    });
  }, [regionsData, mapboxElRef, cityData, addZoomRef, substractZoomRef]);

  return (
    <div className="pc:w-3/5 laptop:w-11/12 tablet:w-11/12 phone:w-11/12 pc:h-auto laptop:h-64 tablet:h-64 phone:h-64 border mt-8 map-min">
      <div className="h-full" ref={(el) => (mapboxElRef.current = el)}></div>
      <div className="flex flex-row justify-between items-center pc:w-4/12 laptop:w-2/4 tablet:w-2/5 phone:w-2/5 z-50 absolute -mt-16 ml-4">
        <img src={legend} alt="legend" />
        <div className="flex flex-col items-center pc:mr-16 laptop:mr-12 tablet:mr-0 phone:mr-0 -mt-16 bg-gray-800 border-gray-800 rounded-lg">
          <span
            className="text-4xl text-white pr-4 pl-4 opacity-50  text-center cursor-pointer border-b"
            ref={(el) => (addZoomRef.current = el)}
          >
            +
          </span>
          <span
            className="text-4xl text-white  pr-4 pl-4 opacity-50 text-center  cursor-pointer"
            ref={(el) => (substractZoomRef.current = el)}
          >
            -
          </span>
        </div>
      </div>
    </div>
  );
};

export default AffectedMap;
