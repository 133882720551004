import gql from "graphql-tag";

export const GET_INFECTED_REGIONS_DATA = gql`
  subscription MySubscription2($orderBy: [covid_summary_by_region_order_by!]) {
    covid_summary_by_region(order_by: $orderBy) {
      ill
      region
      dead
      recovered
    }
  }
`;
