export enum Cases {
  confirmed = "confirmed",
  recovered = "recovered",
  deaths = "deaths",
  active = "active",
}

export enum CasesFieldRef {
  confirmed = "confirmed",
  recovered = "recovered",
  dead = "dead",
  ill = "ill",
}
