export enum RegionCases {
  unexpected = 75,
  small = 175,
  common = 230,
  middle = 350,
  big = 430,
  critical = 430,
}

export enum CityCases {
  unexpected = 150,
  big = 500,
}
