import gql from "graphql-tag";

export const GET_DAILY_CHART = gql`
  subscription GET_DAILY_CHART {
    covid_summary_by_date {
      date
      dead
      ill
    }
  }
`;
