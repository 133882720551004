import gql from "graphql-tag";

export const DAILY_ILL = gql`
  subscription DAILY_ILL($today: date!) {
    covid_data_aggregate(where: { date: { _eq: $today } }) {
      aggregate {
        sum {
          currently_ill
        }
      }
    }
  }
`;
