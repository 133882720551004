import * as React from "react";
import { CaseProps } from "./types";
import { AreaChart, Area } from "recharts";
import { selectCasesType } from "./utils";
import { preventNaNOrNull } from "../RightSidebar/utils";

const Case: React.FC<CaseProps> = ({ type, casesAmount, data, refField }) => {
  const { stopColor, header } = selectCasesType(type);
  console.log(data);
  const arrayCenter = parseInt((data.length / 2).toFixed(0));
  const start = data.slice(0, 5);
  const middle = data.slice(arrayCenter, arrayCenter + 5);
  const end = data.slice(Math.max(data.length - 5, data.length + 5));
  const choosed = [...start, ...middle, ...end];
  return (
    <div className="pt-8 pb-4 pr-2 pl-8 w-64 mr-8 gradient">
      <div className="flex flex-col justify-start items-start ml-4">
        <h3 className="text-white mr-12 text-xl z-10">Total {header}</h3>
        <div className="flex-flex-col">
          <span className="text-4xl text-white mr-8 z-50">
            {preventNaNOrNull(casesAmount)}
          </span>
          <div className="ml-8">
            <AreaChart
              width={150}
              height={50}
              data={choosed}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id={type} x1="0%" y1="30%" x2="40%" y2="100%">
                  <stop offset="0%" stopColor={stopColor} />
                  <stop offset="100%" stopColor="#302F35" />
                </linearGradient>
              </defs>

              <Area
                type="monotone"
                dataKey={refField}
                stroke={stopColor}
                fill={`url(#${type})`}
              />
            </AreaChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Case;
