import * as React from "react";
import { TableRow } from "./types";

const RegionsRow: React.FC<TableRow> = ({
  region,
  cases,
  death,
  mortality,
}) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="w-20 flex flex-row justify-start items-start mt-2">
        <span className="text-white opacity-50 capitalize">{region}</span>
      </div>
      <div className="flex flex-row">
        <div className="flex flex-row mr-4 justify-center items-center w-20 mt-2">
          <span className="text-white text-base opacity-50">{cases}</span>
        </div>
        <div className="flex flex-row mr-4 justify-center items-center w-20 mt-2">
          <span className="text-white text-base opacity-50">{death}</span>
        </div>
        <div className="flex flex-row mr-4 justify-center items-center w-20 mt-2">
          <span className="text-white text-base opacity-50">{mortality} %</span>
        </div>
      </div>
    </div>
  );
};

export default RegionsRow;
