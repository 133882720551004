import * as React from "react";
import { RangeBarItemProps } from "./types";

const RangeBarItem: React.FC<RangeBarItemProps> = ({
  range,
  selected,
  setRange,
}) => {
  return (
    <span
      className={`text-white opacity-50 mr-4 cursor-pointer text-center ${
        selected === range ? "bg-blue-600 w-8 h-7 rounded" : ""
      }`}
      onClick={() => {
        return setRange(range);
      }}
    >
      {range}
    </span>
  );
};

export default RangeBarItem;
