import { Cases } from "../../enums/cases";
export const casesData = [
  {
    name: "Page A",
    uv: 1000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page C",
    uv: 4000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 3800,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 8000,
    pv: 4800,
    amt: 2181,
  },
];

export const selectCasesType = (type: Cases) => {
  switch (type) {
    case Cases.confirmed: {
      return { stopColor: "#f4ac3f", header: "Confirmed" };
    }
    case Cases.recovered: {
      return { stopColor: "#2fe9fe", header: "Recovered" };
    }
    case Cases.active: {
      return { stopColor: "#FE4A4A", header: "Active" };
    }
    case Cases.deaths: {
      return { stopColor: "#787878", header: "Deaths" };
    }
  }
};
