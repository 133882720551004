import * as React from "react";
import { useSubscription } from "@apollo/react-hooks";
import { GET_CASES_TYPES_AMOUNT } from "src/queries/getCaseTypesAmount";
import { selectCurrentWeekDates, preventNaNOrNull } from "./utils";
import { format } from "date-fns";

const InfectedCard: React.FC = () => {
  const currentWeekDates = selectCurrentWeekDates();
  const { data, loading } = useSubscription(GET_CASES_TYPES_AMOUNT, {
    variables: {
      dates: currentWeekDates,
    },
  });

  if (loading) {
    return null;
  }

  const {
    covid_data_aggregate: {
      aggregate: {
        sum: { currently_ill, serious },
      },
    },
  } = data;
  const usualCases = currently_ill - serious;
  const allCases = currently_ill + serious;
  const startWeek = format(new Date(currentWeekDates[0]), "MMM d");
  const endWeek = format(new Date(currentWeekDates[6]), "MMM d");
  return (
    <div className=" flex flex-col justify-center items-center gradient pt-8 pb-8 pr-8 pl-8">
      <h3 className="text-white text-xl text-center">
        Currently Infected Patients by week
      </h3>
      <span className="text-base text-white opacity-50 mt-4">
        {startWeek} - {endWeek}
      </span>
      <span className="text-xl text-white mt-4">
        {preventNaNOrNull(allCases)}
      </span>
      <div className="flex flex-row justify-between items-center mb-4 mt-4 w-full">
        <div className="flex flex-row items-center justify-between">
          <span className="w-2 h-2 bg-blue-300 mr-4"></span>
          <span className="text-sm text-white">In Mild Condition</span>
        </div>
        <span className="text-white text-base">
          {preventNaNOrNull(usualCases)}
        </span>
      </div>
      <div className="flex flex-row justify-between items-center mb-4  w-full">
        <div className="flex flex-row items-center justify-between">
          <span className="w-2 h-2 bg-red-500 mr-4"></span>
          <span className="text-sm text-white">Critical or Serious</span>
        </div>
        <span className="text-white text-base">
          {preventNaNOrNull(serious)}
        </span>
      </div>
    </div>
  );
};

export default InfectedCard;
