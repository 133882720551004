import * as React from "react";

const AreaLayout: React.FC<{ children: React.ReactChild }> = ({ children }) => {
  return (
    <div className="gradient w-3/5 pl-12">
      <h3 className="text-white text-xl mt-8">
        Covid-19 Affected Areas by all Regions
      </h3>
      {children}
    </div>
  );
};

export default AreaLayout;
