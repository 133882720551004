import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import ApolloClient from "apollo-client";
const hasuraUrl = process.env.REACT_APP_HASURA_URL;
const adminSecret = process.env.REACT_APP_HASURA_SECRET;

export const createApolloClient = () => {
  return new ApolloClient({
    link: new WebSocketLink({
      uri: `${hasuraUrl}`,
      options: {
        connectionParams: {
          headers: {
            "x-hasura-admin-secret": adminSecret,
          },
          reconnect: true,
        },
      },
    }),
    cache: new InMemoryCache(),
  });
};
