import { RegionData } from "./types";
import { mapLayers } from "./mapLayers";
import mapboxgl from "mapbox-gl";
import { RegionCases, CityCases } from "src/enums/mapCategories";

export const selectRegionData = (name: string, data: RegionData[]) => {
  const initialRegion = { ill: 0, dead: 0, recovered: 0, region: "" };
  const selectedRegion = data.find((selected) => {
    console.log(selected.region, name.replace("_", " "));
    return selected.region === name.replace("_", " ");
  });
  return selectedRegion ? selectedRegion : initialRegion;
};

export const selectRegionFillColor = (
  regionsData: RegionData[],
  current: string
) => {
  const selectedRegion = regionsData.find((selected) => {
    return selected.region === current.replace("_", " ");
  });

  const amount = selectedRegion ? selectedRegion.ill : 0;

  const isUnexpected = amount < RegionCases.unexpected;
  const isSmall = amount > RegionCases.unexpected && amount < RegionCases.small;
  const isCommon = amount >= RegionCases.small && amount < RegionCases.common;
  const isMiddle = amount >= RegionCases.common && amount < RegionCases.big;
  const isBig = amount >= RegionCases.big && amount < RegionCases.critical;
  const isCritical = amount >= RegionCases.critical;

  if (isUnexpected) {
    return "#1F9372";
  }
  if (isSmall) {
    return "#2FCCA2";
  }
  if (isCommon) {
    return "#FFB891";
  }
  if (isMiddle) {
    return "#FFC73B";
  }
  if (isBig) {
    return "#FF8645";
  }
  if (isCritical) {
    return "#FF5F44";
  }
};

export const selectCityInfected = (amount: number) => {
  const isUnexpected = amount < CityCases.unexpected;
  const isBig = amount >= CityCases.unexpected && amount < CityCases.big;
  const isCritical = amount >= CityCases.big;
  if (isUnexpected) {
    return {
      "circle-color": "#1F9372",
      "circle-stroke-width": 0,
      "circle-radius": 0,
    };
  }
  if (isBig) {
    return {
      "circle-color": "#FF8645",
      "circle-radius": 10,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#FF8600",
    };
  }
  if (isCritical) {
    return {
      "circle-color": "#FF8645",
      "circle-radius": 25,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#FF8600",
    };
  }
};

export const generateCityLayout = (map: any, cityData: any[]) => {
  cityData.forEach((record: any, index: number) => {
    map.addSource(`${record.city}-infected-${index}`, {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {
          name: record.city,
          ill: record.ill,
        },
        geometry: {
          coordinates: [record.lng, record.lat],
          type: "Point",
        },
      },
    });
    map.addLayer({
      id: `${record.city}-circle-${index}`,
      type: "circle",
      source: `${record.city}-infected-${index}`,
      paint: selectCityInfected(record.ill),
    });
    map.addLayer({
      id: `${record.city}-infected-${index}-labels`,
      type: "symbol",
      source: `${record.city}-infected-${index}`,
      layout: {
        "symbol-z-order": "viewport-y",
        "text-field": ["format", ["upcase", ["get", "name"]]],
        "text-anchor": "bottom",
      },
      paint: {
        "text-color": "#ffffff",
      },
    });
    map.addLayer({
      id: `${record.city}-infected-${index}-place`,
      type: "circle",
      source: `${record.city}-infected-${index}`,
      minzoom: 4.5,
      paint: {
        "circle-color": "#ffffff",
        "circle-stroke-color": "#000000",
        "circle-stroke-width": 2,
        "circle-radius": 2,
      },
    });
  });
};

export const generateRegionsLayout = (map: any, regionsData: any) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });
  mapLayers.map((layerData) => {
    map.addSource(layerData.id, {
      type: "vector",
      url: layerData.sourceUrl,
    });
    map.addLayer({
      id: layerData.id,
      type: "fill",
      source: layerData.id,
      "source-layer": `${layerData.id}-${layerData.hash}`,
      paint: {
        "fill-color": selectRegionFillColor(regionsData, layerData.id),
        "fill-outline-color": "#000000",
      },
    });
    map.on("click", layerData.id, (e: any) => {
      map.getCanvas().style.cursor = "pointer";
      const selectedRegion = selectRegionData(layerData.id, regionsData);
      const popupData = `
      <p>region: ${selectedRegion.region}, </p>
        <p>ill: ${selectedRegion.ill}, </p>
        <p> dead: ${selectedRegion.dead}, </p>
        <p>recovered: ${selectedRegion.recovered} </p>
      `;
      popup.setLngLat(e.lngLat).setHTML(popupData).addTo(map);
    });
    return layerData;
  });
};
