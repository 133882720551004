import * as React from "react";
import AreaLayout from "../Layouts/AreaLayout.component";
import AffectedMap from "../AffectedMap/AffectedMap.component";
import RegionsTable from "../RegionsTable/RegionsTable.component";
import ConfirmedCasesChart from "../CasesCharts/ConfirmedCasesChart.component";
import NewDataChart from "../CasesCharts/NewDataChart.component";
import NoChartData from "../CasesCharts/NoChartData.component";

const Stat: React.FC<any> = ({
  orderBy,
  setOrderBy,
  regionsData,
  mapRegionsData,
  cityData,
  dailyChartData,
}) => {
  return (
    <AreaLayout>
      <div>
        <div className="flex pc:flex-row laptop:flex-col tablet:flex-col phone:flex-col">
          <AffectedMap
            regionsData={mapRegionsData.covid_summary_by_region}
            cityData={cityData.covid_summary_by_city}
          />
          <RegionsTable
            regionsData={regionsData.covid_summary_by_region}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
          />
        </div>
        <div className="flex pc:flex-row laptop:flex-col tablet:flex-col phone:flex-col pb-4">
          <ConfirmedCasesChart />
          {dailyChartData.covid_summary_by_date ? (
            <NewDataChart data={[dailyChartData.covid_summary_by_date]} />
          ) : (
            <NoChartData />
          )}
        </div>
      </div>
    </AreaLayout>
  );
};

export default Stat;
