import gql from "graphql-tag";

export const GET_GENERAL_DATA = gql`
  subscription GET_GENERAL_DATA {
    covid_data_aggregate {
      aggregate {
        sum {
          currently_ill
          dead
          recovered
        }
      }
    }
  }
`;
