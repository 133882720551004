import * as React from "react";
import { DatasetModalProps } from "./types";
import Close from "src/styles/svg/Close/Close.component";
import UploadDataset from "./UploadDataset.component";
import { useHistory } from "react-router-dom";
import { Routes } from "src/enums/routes";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/react-hooks";
import { INSERT_USER } from "src/queries/insertUser";

const DatasetModal: React.FC<DatasetModalProps> = ({ onClose }) => {
  const [file, uploadFile] = React.useState({} as File);
  const history = useHistory();
  const [insertUserId] = useMutation(INSERT_USER, {
    onCompleted: (data: any) => {
      const userId = data.insert_users.returning[0].id;
      history.push(`${Routes.privateDashboard}/${userId}`);
    },
  });

  const closeModal = React.useCallback(() => {
    document.body.style.overflow = "scroll";
    onClose(false);
  }, [onClose]);

  const sendDataset = React.useCallback(() => {
    insertUserId({ variables: { id: uuidv4() } });
  }, [insertUserId]);

  return (
    <div className=" flex flex-col justify-between modal h-screen w-2/6 gradient absolute right-0 top-0 z-10">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center border-b border-gray-300 pt-4 pb-4 pr-8 pl-8">
          <span className="text-white text-lg">Upload Dataset</span>
          <span onClick={closeModal}>
            <Close />
          </span>
        </div>
        <div className="flex flex-col ml-8 mt-12">
          <span className="italic text-base text-white opacity-50">
            Uploaded file needs to contain the data, for example:
          </span>
          {!file.name && (
            <div className="flex flex-col pr-4 mt-4 w-11/12">
              <div className="flex flex-row justify-between bg-gray-100 pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Date</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  2020-04-14
                </span>
              </div>
              <div className="flex flex-row justify-between bg-white pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Time</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  14:00:00
                </span>
              </div>
              <div className="flex flex-row justify-between bg-gray-100 pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Region:</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  Riyadh
                </span>
              </div>
              <div className="flex flex-row justify-between bg-white pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Recovered:</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  245
                </span>
              </div>
              <div className="flex flex-row justify-between bg-gray-100 pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Dead:</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  4
                </span>
              </div>
              <div className="flex flex-row justify-between bg-gray-100 pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">Currentry ill:</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  241
                </span>
              </div>
              <div className="flex flex-row justify-between bg-white pr-2 rl-2">
                <span className="pt-2 pb-2 pl-2">City:</span>
                <span className="border-l borger-gray-100 pl-4 h-full pt-2 pb-2 w-8/12">
                  Riyadh
                </span>
              </div>
            </div>
          )}
          <UploadDataset file={file} uploadFile={uploadFile} />
        </div>
      </div>
      <div className="flex flex-row justify-end items-center border-t border-gray-300 w-full pt-4 pb-4">
        <div
          className="flex items-center justify-center border border-gray-300 mr-4 w-32 h-12 rounded cursor-pointer"
          onClick={closeModal}
        >
          <span className="uppercase text-sm text-white opacity-50 pointer">
            Cancel
          </span>
        </div>
        <div
          className={`flex items-center justify-center bg-green-200  mr-4 w-32 h-12 rounded ${
            file.name ? "cursor-pointer" : ""
          }`}
          onClick={sendDataset}
        >
          <span className="uppercase text-sm text-black-450 opacity-50">
            {file.name ? "Apply" : "Upload"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DatasetModal;
