import * as React from "react";
import { IconProps } from "../icon.props";

const UploadFile: React.FC<IconProps> = ({
  width = 15,
  height = 14,
  viewBox = "0 0 15 14",
  fill = "white",
  fillOpacity = "0.2",
  className = "cursor-pointer",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={viewBox}
      {...props}
    >
      <path
        d="M13.8318 6.59098C13.6051 6.59098 13.4238 6.77674 13.4238 7.00895V10.811C13.4238 11.8451 12.6018 12.6841 11.5955 12.6841H2.64427C1.63491 12.6841 0.815945 11.842 0.815945 10.811V6.94703C0.815945 6.71482 0.634624 6.52905 0.407972 6.52905C0.181321 6.52905 0 6.71482 0 6.94703V10.811C0 12.3064 1.18765 13.5201 2.64427 13.5201H11.5955C13.0551 13.5201 14.2397 12.3033 14.2397 10.811V7.00895C14.2397 6.77984 14.0584 6.59098 13.8318 6.59098Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path
        d="M4.81345 3.37176L6.71129 1.42735V9.90784C6.71129 10.1401 6.89261 10.3258 7.11926 10.3258C7.34592 10.3258 7.52724 10.1401 7.52724 9.90784V1.42735L9.42507 3.37176C9.50365 3.45226 9.60942 3.49561 9.71217 3.49561C9.81794 3.49561 9.92069 3.45536 9.99926 3.37176C10.1594 3.20766 10.1594 2.94448 9.99926 2.78039L7.40636 0.123848C7.33081 0.046443 7.22503 0 7.11926 0C7.01047 0 6.90772 0.0433468 6.83217 0.123848L4.23927 2.78039C4.0791 2.94448 4.0791 3.20766 4.23927 3.37176C4.39641 3.53276 4.65631 3.53276 4.81345 3.37176Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default UploadFile;
