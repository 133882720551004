import gql from "graphql-tag";

export const GET_DATA_BY_HOURS = gql`
  subscription GET_DATA_BY_HOURS($start: time, $end: time, $day: date) {
    covid_summary_by_hours(
      where: { time: { _gte: $start, _lte: $end }, date: { _eq: $day } }
    ) {
      time
      ill
      region
    }
  }
`;
