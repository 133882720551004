import React from "react";
import Header from "../Header/Header.component";
import PageLayout from "../Layouts/PageLayout.component";

const Dashboard = () => {
  return (
    <div className="w-full min-h-screen">
      <Header />
      <div className="w-full bg-black-500 pl-16 pt-8 pb-4 min-h-screen">
        <PageLayout />
      </div>
    </div>
  );
};

export default Dashboard;
