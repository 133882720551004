import * as React from "react";
import { PercentCardProps } from "./types";
import { selectCardType, preventNaNOrNull } from "./utils";

const PercentCard: React.FC<PercentCardProps> = ({ percent, type }) => {
  const { header, textColor } = selectCardType(type);
  return (
    <div className="flex flex-col justify-center items-center gradient pt-8 pb-8 mt-12 pl-16 pr-16 h-64">
      <h4 className="text-white opacity-50 text-lg">{header}</h4>
      <span className={`text-5xl ${textColor} mt-4"`}>
        {preventNaNOrNull(percent)} %
      </span>
    </div>
  );
};

export default PercentCard;
