import gql from "graphql-tag";

export const SELECT_STAT_DATA_BY_DAY = gql`
  subscription SELECT_GENERAL_DATA_BY_DAY {
    covid_stat_general(order_by: { date: asc }) {
      date
      dead
      ill
      recovered
      confirmed
    }
  }
`;
