import { StatTypes } from "src/enums/statTypes";

export const selectCardType = (type: StatTypes) => {
  switch (type) {
    case StatTypes.daily: {
      return { header: "Daily Case Growth", textColor: "text-orange-500" };
    }
    case StatTypes.recovery: {
      return {
        header: "Ratio of Recovery",
        textColor: "text-green-100",
      };
    }
    case StatTypes.mortality: {
      return {
        header: "Mortality",
        textColor: "text-gray-700",
      };
    }
  }
};

export const selectCurrentWeekDates = () => {
  let week = [1, 2, 3, 4, 5, 6, 7].reduce((acc: string[], curr: number) => {
    let date = new Date();
    let first = date.getDate() - curr;
    let day = new Date(date.setDate(first)).toISOString().slice(0, 10);
    return [...acc, day];
  }, []);
  return week;
};

const parseDateFragment = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  if (number > 30) {
    return 30;
  }
  return number;
};

export const selectCurrentMonthDates = () => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const amountOfDays = new Date(currentYear, currentMonth, 0).getDate();
  return Array.from({ length: amountOfDays }).map((el, index) => {
    let date = new Date();
    let first = date.getDate() - index;
    let day = new Date(date.setDate(first)).toISOString().slice(0, 10);
    return day;
  });
};

export const selectDayHours = () => {
  return Array.from({ length: 24 }).map(
    (el, index) => `${parseDateFragment(index)}:00:00`
  );
};

export const preventNaNOrNull = (field: number) => {
  if (isNaN(field) || field === null) {
    return 0;
  }
  return field;
};
