import gql from "graphql-tag";

export const GET_DATA_BY_DAYS = gql`
  subscription GET_DATA_BY_DAYS($dates: [date!]) {
    covid_summary_by_date_region(where: { date: { _in: $dates } }) {
      date
      ill
      region
    }
  }
`;
