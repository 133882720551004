import gql from "graphql-tag";

export const GET_DATA_FROM_YESTERDAY = gql`
  subscription GET_DATA_FROM_YESTERDAY($time: time, $date: date) {
    covid_summary_by_hours(where: { date: { _gte: $date } }) {
      time
      ill
      region
    }
  }
`;
