export const mapLayers = [
  {
    id: "Jizan",
    sourceUrl: "mapbox://tomoyax1.4ogq43lo",
    hash: "00yseb",
  },
  {
    id: "Asir",
    sourceUrl: "mapbox://tomoyax1.7rdc4z1v",
    hash: "0zm4zb",
  },
  {
    id: "Najran",
    sourceUrl: "mapbox://tomoyax1.9m9wroho",
    hash: "99h0ip",
  },
  {
    id: "Eastern_Province",
    sourceUrl: "mapbox://tomoyax1.brhd2q36",
    hash: "bwtno0",
  },
  {
    id: "Riyadh",
    sourceUrl: "mapbox://tomoyax1.0ckmwlv2",
    hash: "67xopg",
  },
  {
    id: "Qassim",
    sourceUrl: "mapbox://tomoyax1.373uej8e",
    hash: "5grw12",
  },
  {
    id: "Madinah",
    sourceUrl: "mapbox://tomoyax1.1y93pjgm",
    hash: "0w11bk",
  },
  {
    id: "Tabuk",
    sourceUrl: "mapbox://tomoyax1.7gd4apd2",
    hash: "7ynv7b",
  },
  {
    id: "Makkah",
    sourceUrl: "mapbox://tomoyax1.5n01dtbe",
    hash: "8mh78r",
  },
  {
    id: "Northern_Borders",
    sourceUrl: "mapbox://tomoyax1.akfpgils",
    hash: "6kkb0i",
  },
  {
    id: "alJawf",
    sourceUrl: "mapbox://tomoyax1.81wxb2d6",
    hash: "1z6m8d",
  },
  {
    id: "Hail",
    sourceUrl: "mapbox://tomoyax1.12g3ax8i",
    hash: "057gpl",
  },
  {
    id: "alBahah",
    sourceUrl: "mapbox://tomoyax1.5609afgg",
    hash: "drut62",
  },
];
