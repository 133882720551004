import * as React from "react";

const ClickOutside: React.FC<{
  children: React.ReactChild;
  handleClick: any;
}> = ({ children, handleClick }) => {
  const wrapperRef = React.useRef() as any;

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        handleClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, handleClick]);
  return (
    <div ref={wrapperRef} className="z-50">
      {children}
    </div>
  );
};

export default ClickOutside;
