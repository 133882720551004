import * as React from "react";
import UploadFile from "src/styles/svg/Upload/Upload.component";

const UploadDataset: React.FC<{
  file: any;
  uploadFile: React.Dispatch<React.SetStateAction<any>>;
}> = ({ file, uploadFile }) => {
  const inputRef = React.useRef<any>();
  const handleFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploaded = e.target.files ? e.target.files[0] : null;
      uploadFile(uploaded);
    },
    [uploadFile]
  );

  return (
    <div className="flex flex-col items-start justify-start mt-8 w-full">
      <span className="text-white opacity-50 text-lg">Upload file</span>
      <div className="flex flex-row justify-between items-center border rounded border-gray-300 pl-4 pt-2 pb-2 w-11/12 h-12">
        <input
          className="hidden"
          type="file"
          ref={inputRef}
          onChange={handleFile}
          accept=".csv"
        />
        <span className="text-base text-white opacity-50">
          {file.name ? file.name : "Add file or drop it here"}
        </span>
        <div className="flex items-center justify-center mr-2 bg-gray-800 w-8 h-8 rounded pointer">
          <UploadFile onClick={() => inputRef.current.click()} />
        </div>
      </div>
      <span className="text-base text-white opacity-50 mt-2">
        for .csv format only
      </span>
    </div>
  );
};

export default UploadDataset;
