import * as React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";

const NewDataChart: React.FC<any> = ({ data }) => {
  const parsedChartData = data[0].map((element: any) => ({
    ...element,
    date: format(new Date(element.date), "MMM dd"),
  }));
  return (
    <div className=" flex-flex-col items-start justify-start border border-gray-700 rounded mt-24 pt-4 pr-4 pc:ml-8 laptop:ml-0 tablet:ml-0 phone:ml-0 pc:w-5/12 laptop:w-11/12 tablet:w-11/12 phone:w-11/12">
      <h3 className="text-white text-lg mt-4 ml-16">
        New Cases and Deaths by Day
      </h3>
      <div className="flex flex-row items-center justify-start ml-16 mt-4">
        <span className="w-4 h-4 bg-orange-500 mr-4"></span>
        <span className="text-white">New Cases</span>
        <span className="w-4 h-4 bg-gray-700 mr-4 ml-16"></span>
        <span className="text-white">Deaths</span>
      </div>

      <ResponsiveContainer width="100%" height={420}>
        <ComposedChart
          data={parsedChartData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip
            contentStyle={{ backgroundColor: "#36363E" }}
            labelStyle={{ color: "white" }}
          />
          <Bar dataKey="ill" barSize={20} fill="#F4AC3F" />
          <Line type="monotone" dataKey="dead" stroke="#787878" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NewDataChart;
