import gql from "graphql-tag";

export const GET_CASES_TYPES_AMOUNT = gql`
  subscription GET_CASES_TYPES_AMOUNT($dates: [date!]) {
    covid_data_aggregate(where: { date: { _in: $dates } }) {
      aggregate {
        sum {
          serious
          currently_ill
        }
      }
    }
  }
`;
